import { Component, Input, OnInit } from "@angular/core";
import { IUser } from "src/app/interfaces/user.interface";
import { AuthService } from "src/app/services/auth.service";
import { UserAdminService } from "src/app/services/user.service";
import { delay } from "src/app/util/util";

const CARD_STYLE = { 
  style: { 
    base: { 
      fontSize: '16px',
      '::placeholder': {
        fontSize: '14px',
        color: '#777'
      }
    } 
  } 
}

@Component({
  selector: "payment-info",
  templateUrl: "./payment-info.component.html",
  styleUrls: ["./payment-info.component.scss"],
})
export class PaymentInfo implements OnInit {
	user: IUser
  stripe: stripe.Stripe
	card: stripe.elements.Element
	cards: { brand: string, last4: number, exp_month: string, exp_year: string, email: string }[] = []
  // client: 
	@Input() onCardChange: (event: stripe.elements.ElementChangeResponse, stripeInst: stripe.Stripe, card: stripe.elements.Element) => void

  constructor(
    public userService: UserAdminService,
    public auth: AuthService
  ) {}

  async ngOnInit() {
		this.user = this.auth.getUser()
    await this.load()
  }

	/**
	 * @todo this will need to load the payment methods for a user
	 */
	async load() {
		const stripekey: any = await this.auth.getStripeKey().toPromise();
		this.stripe = Stripe(stripekey.key)

		// TODO add existing cards

		if (!this.cards.length) {
			await this.displayCardInput()
		}
	}

	async displayCardInput() {
    // const intent = 
    // this.addingCard = true
    // await delay(50)

    const elements = this.stripe.elements()
    const card = elements.create('card', CARD_STYLE)
    this.card = card
    card.mount('#card-element')


		card.on('change', (event) => this.onCardChange ? this.onCardChange(event, this.stripe, card) : displayError(event))
		function displayError(event) {
			console.log('-- event: ', event)
			let displayError = document.getElementById('card-element-errors');
			if (event.error) {
				displayError.textContent = event.error.message;
				displayError.style.color = '#eb1c26';
			} else {
				displayError.textContent = '';
			}
		}

		card.on('focus', (e) => $('#ctr').addClass('activated'))
    card.on('blur', () => $('#ctr').removeClass('activated'))
  }

  async addCard() {
    // this.loading = true
    const intent = await this.userService.getStripeIntent(this.user._id);
    const cardParams = { payment_method: { card: this.card } };
    const res = await this.stripe.confirmCardSetup(intent.client_secret, cardParams)
    // if (res.setupIntent) {
    //   alert('success! toastify me later')
    // } else {
    //   alert('-- ERROR: fix me :( then toastify me')
    // }
    await this.load()
    // this.addingCard = false
    // this.loading = false
    // if (this.onDone && this.ogTier && this.cards.length) {
    //   this.onDone()
    // }
  }
}