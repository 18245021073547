import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IProperty } from "../interfaces/property.interface";

interface SearchAddress {
	addressLine1: string
	addressLine2: string
	city: string
	state: string
	zip: string
	formatted_address: string
}

@Injectable()
export class PropertyService2 {
	constructor(private readonly httpClient: HttpClient) {}

	async search(address: SearchAddress): Promise<{ property: IProperty }> {
		return this.httpClient.post<{ property: IProperty }>('property2/search', { address }).toPromise()
	}

	async sendLeadgenLink(id, emails, subject, message, ccMyEmail = false): Promise<{ property: IProperty, newprofile: any }> {
		return this.httpClient.post<{ property: IProperty, newprofile: any }>(`property2/${id}/leadgen`, { emails, subject, message, ccMyEmail }).toPromise()
	}

	async sendLeadgenTemplateLink(properties: IProperty[], template): Promise<any> {
		return this.httpClient.post<any>(`property2/leadgen/batch`, { properties, template }).toPromise()
	}
}