import { Injectable } from "@angular/core"
import { AbstractControl, AsyncValidatorFn, ValidationErrors, Validators } from "@angular/forms"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { UserAdminService } from "./user.service"

@Injectable()
export class ValidationService {
	public emailValidation = {
      validators: [
        Validators.required, 
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), // Validator.email allows a@b
      ],
      asyncValidators: [this.emailAvailability()],
      updateOn: 'blur'
	}

	constructor(public userService: UserAdminService) {}

	emailAvailability(): AsyncValidatorFn {
		console.log('email availabiltiy')
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			console.log('.... in the control function ', control.value)
			return this.userService.checkEmail(control.value)
				.pipe(map(available => {
					console.log('--- A = ', available)
					if (!available) {
						return { alreadyExists: 'Email already in use.' }
					}
					return null
				}))
			}
	}
}