import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsPage } from "./pages/settings/settings";

import { HomePage } from "./pages/home/home";
import { LoginPage } from "./pages/login/login";
import { NotificationsPage } from "./pages/notifications/notifications";
import { RegisterPage } from "./pages/register/register";
import { RegisterActivate } from './pages/register-activate/register-activate';
import { PropertyDetailPage } from "./pages/property-detail/property-detail";
//import {PropertylistPage} from "./pages/propertylist/propertylist";
import { LocalWeatherPage } from "./pages/local-weather/local-weather";
import { PictureLaodPage } from "./pages/pictureload/pictureload";
import { ReorderPropertyImages } from './pages/reorder-property-images/reorder-property-images';
import { CreatePropertyPage } from "./pages/createproperty/createproperty";
import { OrgAdminPage } from "./pages/admin/org-admin";
import { AdminUserMgtPage } from './pages/admin-user-mgt/admin-user-mgt';
import { ModifierAdminPage } from "./pages/admin/modifier-admin";
import { ProfilePage } from './pages/profile/profile';
import { BillingDetails } from './pages/billing-details/billing-details';
import { AppLayoutComponent } from './pages/applayout/applayout.component'
import { SimulatorComponent } from './pages/simulator/simulator.component';
import { RegisterSuccessPage } from './pages/register-success.ts/register-success';
import { PropertyAdminPage } from './pages/admin/property-admin';
import { TeamPage } from './pages/team/team';
import { ResetPass } from './pages/resetpass/resetpass';
import { Subscription } from './components/subscription/subscription';
import { RegisterVerifyEmailPage } from './pages/register-verify-email/register-verify-email';
import { RegistrationCtr } from './pages/registration-ctr/registration-ctr';
import { PropertyDetailPage_Test } from './pages/property-detail-test/property-detail-test';
import { PropertyDetailGuestPage } from './pages/property-detail-guest/property-detail-guest';
import { VideoPage } from './pages/video/video';

const routes: Routes = [
  { path: '', loadChildren: './pages/login/login.module#LoginPageModule' },
  // account routes
  { path: 'register', component: RegistrationCtr },
  { path: 'activate/:keytoken', component: RegisterActivate },
  { path: 'resetpass/:code', component: ResetPass },
  // app routes
  { path: 'home', component: HomePage },
  { path: 'team', component: TeamPage },
  { path: 'simulator', component: SimulatorComponent },
  // settings routes
  { path: 'edit-profile', component: ProfilePage },
  { path: 'edit-profile/billing-details', component: BillingDetails },
  // property routes
  { path: 'create', component: CreatePropertyPage },
  { path: 'property/:id', component: PropertyDetailPage }, // v2 testing code in PropertyDetailPage_Test
  { path: 'guest/:id/:link', component: PropertyDetailPage }, // id = property id
  { path: 'reorder-property-images', component: ReorderPropertyImages }, // old page was /picture and file was PictureLoadPage
  // admin routes
  { path: 'admin/org', component: OrgAdminPage },
  { path: 'admin/users', component: AdminUserMgtPage },
  { path: 'admin/lboadmin', component: ModifierAdminPage },
  { path: 'admin/property', component: PropertyAdminPage },
  // misc routes
  // NOTE: this file should probably just turn into a modal
  { path: 'video', component: VideoPage }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
