import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MESSAGE_2 } from 'src/app/components/share-valu-modal/share-valu-constants';
import { AuthService } from 'src/app/services/auth.service';
import { ToastController } from '@ionic/angular';
import { UserAdminService } from 'src/app/services/user.service';
import * as moment from 'moment';
import { IUser, UserLeadgenTemplate } from 'src/app/interfaces/user.interface';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash'

const DEFAULT_TEMPLATE = (user: IUser): UserLeadgenTemplate => 
  ({ _id: null, name: 'Default', subject: `See your home's value!`, content: MESSAGE_2(user), isDefault: true })

interface EmailTemplateUpdates {
  template: UserLeadgenTemplate
  backendSynced: boolean
}

@Component({
  selector: 'leadgen-email-form',
  templateUrl: './leadgen-email-form.component.html',
  styleUrls: ['./leadgen-email-form.component.scss']
})
export class LeadGenEmailFormComponent implements OnInit {
  public user: IUser
  public templates: UserLeadgenTemplate[]
  public templateForm = this.formBuilder.group({
    _id: [''],
    name: ['', Validators.required],
    subject: ['', Validators.required],
    content: ['', [Validators.required, linkValidator()]],
    isDefault: [true]
  })
  public originalTemplate: UserLeadgenTemplate
  @Output() formUpdated: EventEmitter<EmailTemplateUpdates> = new EventEmitter<EmailTemplateUpdates>()
  @Input() disabled = false
  @Input() defaultTemplate

  constructor(
    public authService: AuthService,
    public formBuilder: FormBuilder,
    public toastCtl: ToastController ,
    public userService: UserAdminService
  ) {}

  async ngOnInit() {
    await this.loadTemplates()
    if (this.defaultTemplate) {
      this.selectTemplate(this.defaultTemplate)
      this.originalTemplate = this.defaultTemplate
    }
    this.formUpdated.emit({ template: this.originalTemplate, backendSynced: true }) // send back the first loaded value
    this.templateForm.valueChanges.subscribe((updatedValue) => {
      this.formUpdated.emit({ template: updatedValue, backendSynced: this.isSaveButtonDisabled })
    })
    if (this.disabled) {
      this.templateForm.disable()
    }
  }

  async loadTemplates() {
    this.user = await this.authService.getFreshUser(true)
    let defaultTpl = this.user.leadgenTemplates.find(t => t.isDefault)
    if (!defaultTpl) {
      defaultTpl = DEFAULT_TEMPLATE(this.user)
      this.templates = [defaultTpl, ...this.user.leadgenTemplates]
    } else {
      this.templates = _.cloneDeep(this.user.leadgenTemplates)
    }
    this.selectTemplate(defaultTpl) 
  }

  async showToast(message) {
    const toast = await this.toastCtl.create({ message, position: 'top', duration: 3000 })
    await toast.present()
  }

  handleTemplateClick(template: UserLeadgenTemplate) {
    if (this.disabled) {
      return
    }
    this.selectTemplate(template)
  }

  /**
   * Reset the form to an existing (or brand new) template.
   * @param template The template to (fresh) transition the form to
   */
  selectTemplate(template: UserLeadgenTemplate) {
    this.templateForm.patchValue(template);
    this.originalTemplate = _.cloneDeep(template)
    this.templateForm.markAsPristine();
    this.templateForm.markAsUntouched();
    // double tap for the event emitter
    this.templateForm.patchValue(template);
  }

  createNewTemplate() {
    this.selectTemplate({
      ...DEFAULT_TEMPLATE(this.user),
      name: `New Template ${moment().format('M/D/YY hh:mma')}`
    })
  }

  /** @todo handle the create new form */
  get isSaveButtonDisabled(): boolean {
    return !this.templateForm.dirty || _.isEqual(this.templateForm.value, this.originalTemplate) || !this.templateForm.valid
  }

  async saveTemplate() {
    if (window.confirm('Sure you want to create this template?')) {
      try {
        const res = await this.userService.saveTemplate(this.user._id, this.templateForm.value)
        await this.loadTemplates()
        this.selectTemplate(res.template)
      } catch (e) {
        console.error('Error saving template: ', e)
        await this.showToast('Error saving template')
      }
    }
  }
}

export function linkValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value && !value.includes('{{link}}')) {
      return { linkInvalid: true };
    }
    return null;
  };
}

