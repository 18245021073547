import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUserlogin, IUserlogin2, IUserlogin3 } from '../interfaces/userlogin.interface';
import { Events } from '@ionic/angular';
import { IUser } from '../interfaces/user.interface';

@Injectable()
export class AuthService {


  constructor(
    private readonly httpClient: HttpClient,
    private readonly configService: ConfigService,
    private readonly jwtHelper: JwtHelperService,
    private readonly events: Events
  ) {

  }

  signUp(
    email: string,
    password: string,
    firstname: string,
    lastname: string,
    // old square billing fields
    // nonce: string, address1: string, city: string, state: string, zip: string
    phone: string,
    brokerage: string,
    tier: string,
    brokerage_code: string,
    account_lead: boolean,
    coupon: string
    // ): Observable<Boolean> {
  ): Observable<IUserlogin3> {
    return this.httpClient.post('auth/local/signup', {
      email,
      password,
      firstname,
      lastname,
      // address1,
      // city,
      // state,
      // zip,
      // nonce
      phone,
      brokerage,
      tier,
      brokerage_code,
      account_lead,
      coupon
    }).pipe(map((userlogin: IUserlogin3) => {
      // turn off the auto login, this user isn't activated but we don't need the error anymore
      if (userlogin && userlogin.token) {
        localStorage.setItem('token', userlogin.token.token);
        localStorage.setItem('userprofile', JSON.stringify(userlogin.user));
        this.events.publish('user:profile', userlogin.user);
      }
      // return !!this.getToken() && !this.jwtHelper.isTokenExpired();
      return userlogin;
    }));
  }

  signIn(email: string, password: string, token?: string): Observable<Boolean> {
    /*this.httpClient.post('auth/local/signin'), {
      email,
      password
    }).subscribe((token: IToken) => {
      localStorage.setItem('token', token.token);
    });
*/

    return this.httpClient.post('auth/local/signin', {
      email,
      password,
      token
    }).pipe(map((userlogin: IUserlogin) => {
      if (userlogin && userlogin.token) {
        localStorage.setItem('token', userlogin.token.token);
        localStorage.setItem('userprofile', JSON.stringify(userlogin.user));
        this.events.publish('user:profile', userlogin.user);
      }
      return !!this.getToken() && !this.jwtHelper.isTokenExpired();
    }));
  }

  changePassword(email: string, password: string, token: string): Observable<Boolean> {

    return this.httpClient.post('auth/local/changepass', {
      email,
      password,
      token
    }).pipe(map((userlogin: IUserlogin) => {
      if (userlogin && userlogin.token) {
        localStorage.setItem('token', userlogin.token.token);
        localStorage.setItem('userprofile', JSON.stringify(userlogin.user));
        this.events.publish('user:profile', userlogin.user);
      }
      return !!this.getToken() && !this.jwtHelper.isTokenExpired();
    }));
  }

  resetPassword(email: string, password: string): Observable<Boolean> {
    return this.httpClient.put('auth/local/resetpass', {
      email,
      password
    }).pipe(map((userlogin: IUserlogin) => {
      if (userlogin && userlogin.token) {
        localStorage.setItem('token', userlogin.token.token);
        localStorage.setItem('userprofile', JSON.stringify(userlogin.user));
        this.events.publish('user:profile', userlogin.user);
      }
      return !!this.getToken() && !this.jwtHelper.isTokenExpired();
    }));
  }

  requestFacebookRedirectUri(): Observable<any> {
    return this.httpClient.get('auth/facebook/uri');
  }

  facebookSignIn(code: string): Observable<any> {
    return this.httpClient.post('auth/facebook/signin', { code });
  }

  requestTwitterRedirectUri(): Observable<any> {
    return this.httpClient.get('auth/twitter/uri');
  }

  twitterSignIn(oauth_token: string, oauth_verifier: string): Observable<any> {
    return this.httpClient.post('auth/twitter/signin', { oauth_token, oauth_verifier });
  }

  requestGoogleRedirectUri(): Observable<any> {
    return this.httpClient.get('auth/google/uri');
  }

  googleSignIn(code: string): Observable<any> {
    return this.httpClient.post('auth/google/signin', { code });
  }

  getProtected(): Observable<any> {
    return this.httpClient.get('auth/authorized');
  }

  getToken(): string {
    try {
      const user: any = JSON.parse(localStorage.getItem('userprofile'));
      // userprofile
      if (user) {
        this.events.publish('user:profile', user);
      }
      return localStorage.getItem('token');
    } catch (e) {
      return null;
    }
  }

  getUser(): IUser {
    try {
      const user: any = JSON.parse(localStorage.getItem('userprofile'));
      // userprofile
      if (user) {
        this.events.publish('user:profile', user);
      }
      return user;
    } catch (e) {
      return null;
    }
  }

  async getFreshUser(setToken = false): Promise<IUser> {
    const user = this.getUser()
    const freshUser = await this.httpClient.get<any>('users/me').toPromise()
    console.log('-- freshUser = ', freshUser)
    if (setToken) {
      localStorage.setItem('userprofile', JSON.stringify(freshUser));
      this.events.publish('user:profile', freshUser);
    }
    return freshUser
  }

  async refreshtoken(): Promise<any> {
    const oldUser = this.getUser();
    console.log("OG YSER: ", oldUser)

    const userlogin = await this.httpClient.post<any>('auth/local/refreshtoken', { user: oldUser }).toPromise()
    console.log("--- NEW TOKEN ", userlogin)
    localStorage.setItem('token', userlogin.token.token);
    localStorage.setItem('userprofile', JSON.stringify(userlogin.user));
    this.events.publish('user:profile', userlogin.user);

    return userlogin
  }

  signOut(): void {
    localStorage.clear();
    this.events.publish('user:logout');
  }

  isAuthenticated(): Observable<Boolean> {
    // return true;
    // return !!this.getToken() && !this.jwtHelper.isTokenExpired();
    return this.getProtected().pipe(map(res => {
      return (res ? !!this.getToken() && !this.jwtHelper.isTokenExpired() && res.authorized : false);
    }));
  }

  getSquareWebToken(): Observable<any> {
    return this.httpClient.get<any>('users/squarewebtoken');
  }

  /**
   * Upon successful registration, the client can ask the backend for a valid
   * Stripe session URL to store payment info.
   * @param tier 
   * @param email 
   * @returns 
   */
  getCheckout(tier: string, email: string) {
    return this.httpClient.get(`users/checkout?tier=${tier}&email=${email}`)
  }

  getCheckoutPartial(email: string) {
    return this.httpClient.get(`users/checkout/partial?email=${email}`)
  }

  /**
   * When routed back from Stripe Session, we pass the session_id to the backend
   * to map the successfully created stripe_customer_id and register the randomCode
   * @param session_id 
   * @returns 
   */
  successfulRegistration(session_id: string) {
    return this.httpClient.post('users/checkout/success', { session_id })
  }

  deleteAccount() {
    const user = this.getUser()
    return this.httpClient.delete('auth/account');
  }

  getStripeKey() {
    return this.httpClient.get('auth/stripekey')
  }

  charge(token: any) {
    return this.httpClient.post<any>(`auth/charge`, token)
  }

  decodeActivationToken(token: string) {
    return this.httpClient.post<any>(`auth/decodeActivationToken`, { token })
  }

  sendActivationEmail(): Promise<any> {
    return this.httpClient.post<any>(`auth/activation/link/email`, {}).toPromise()
  }

  async activateAccount(keytoken: string, activationLinkId: string): Promise<any> {
    const res = await this.httpClient.post<IUserlogin>(
      `auth/activation/link`,
      { keytoken, activationLinkId }
    ).toPromise()
    if (res.token) {
      localStorage.setItem('token', res.token.token);
      localStorage.setItem('userprofile', JSON.stringify(res.user));
      this.events.publish('user:profile', res.user);
      return true
    }
    return false
  }

  activateByCode(code: string): Promise<{ user: IUser }> {
    return this.httpClient.post<any>(`auth/activation/code`, { code }).toPromise()
  }
}
